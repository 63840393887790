import React from "react";
import Flex from "../Styles/styledComponent/Flex";
import Text from "../Styles/styledComponent/Text";
import CustomComponent from './CustomComponent';

function Main() {
  const hikeComponents = [

    {
      date: "April 27",
      location: "Sandstone Canyon Trail",
      length: "4.0 mi",
      elevationGain: "528 ft",
      distanceFromStLouis: "45 minutes",
      link: "https://www.alltrails.com/trail/us/missouri/sandstone-canyon-trail",
    },
    {
      date: "June 8",
      location: "Lewis and Clark Trail and Clark Trail Loop",
      length: "5.1 mi",
      elevationGain: "574 ft",
      distanceFromStLouis: "35 minutes",
      link: "https://www.alltrails.com/trail/us/missouri/lewis-and-clark-trail-and-clark-trail-loop--2",
    },
    {
      date: "September 28",
      location: "Whispering Pines North Loop",
      length: "6.3 mi",
      elevationGain: "849 ft",
      distanceFromStLouis: "75 minutes",
      link: "https://www.alltrails.com/trail/us/missouri/whispering-pines-short-loop?apple_oauth=signup&is_collab_list_invite=false",
    },
  ];

  return (
    <div>
      <Flex
        dir="column"
        justify="around"
        css={{
          width: "100%",
          padding: "20px", // Add padding for better spacing
        }}
      >
        <Text
          css={{
            display1: "800",
            paddingTop: "$7",
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            width: "100%", // Ensures the text takes up the full width
            textAlign: "center", // Centers the text on smaller screens
            "@bp2": {
              display2: "800",
            },
            "@bp4": {
              display3: "800",
            },
            "@bp5": {
              headline1: "500",
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          Take MO Hikes <br /> Group Hikes <br /> In the Missouri and Illinois Area
        </Text>
        <Text
          css={{
            textAlign: "center", // Center the text on smaller screens
            padding: "10px",
            backgroundColor: "#003314",
            marginTop: "10px",
          }}
        >
          "You’re off to great places, today is your day. Your mountain is
          waiting, so get on your way.” - Dr. Seuss
        </Text>
        <Text
          css={{
            textAlign: "center", // Center the text on smaller screens
            padding: "10px",
            backgroundColor: "#003314",
            marginTop: "0px",
          }}
        >
          Feel free to share and invite others. This is a non sponsored event put together for those who enjoy hiking to enable them to get together and experience the trails.
        </Text>
        <Text
          css={{
            textAlign: "center", // Center the text on smaller screens
            padding: "10px",
            backgroundColor: "#003314",
            marginTop: "0px",
          }}
        >
          If there is more than a 50% chance of inclement weather the hike will be cancelled.
        </Text>
        <div style={{ padding: "10px", backgroundColor: "#003314", marginTop: "0px" }}>
          {hikeComponents.map((hike, index) => (
            <CustomComponent
              key={index}
              text1={hike.date}
              text2={"Location"}
              text3={hike.location}
              text4={"Length"}
              text5={hike.length}
              text6={"Elevation Gain"}
              text7={hike.elevationGain}
              text8={"Distance From St. Louis"}
              text9={hike.distanceFromStLouis}
              text10={"All Trails"}
              text11={hike.link}
            />
          ))}
        </div>
      </Flex>
    </div>
  );
}

export default Main;
