import React from 'react';
import Flex from '../Styles/styledComponent/Flex';
import Text from '../Styles/styledComponent/Text';
import { link } from 'fs';

interface CustomComponentProps {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  text6: string;
  text7: string;
  text8: string;
  text9: string;
  text10: string;
  text11: string;
}

const CustomComponent: React.FC<CustomComponentProps> = ({ text1, text2, text3, text4, text5, text6, text7, text8, text9, text10, text11, }) => {
  return (
    <Flex dir="column" css={{ padding: '$4' }}>
      <Text
        css={{
          display1: '700',
          color: '$onBg800',
          paddingBottom: '$2',
          fontSize: '24px', // Adjust the font size as needed
        }}
      >
        {text1}
      </Text>
      <Text
        css={{
          body1: '',
          fontWeight: 'bold',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text2}
      </Text>
      <Text
        css={{
          body1: '',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text3}
      </Text>
      <Text
        css={{
          body1: '',
          fontWeight: 'bold',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text4}
      </Text>
      <Text
        css={{
          body1: '',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text5}
      </Text>
      <Text
        css={{
          body1: '',
          fontWeight: 'bold',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text6}
      </Text>
      <Text
        css={{
          body1: '',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text7}
      </Text>
      <Text
        css={{
          body1: '',
          fontWeight: 'bold',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text8}
      </Text>
      <Text
        css={{
          body1: '',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text9}
      </Text>
      <Text
        css={{
          body1: '',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text10}
      </Text>
      <Text
        css={{
          body1: '',
          color: '$onBg800',
          paddingBottom: '$2',
        }}
      >
        {text11}
      </Text>
      
    </Flex>
  );
};

export default CustomComponent;
export {};
